import { Template } from '@/components/types';

import { Box, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import CollectionStatBox from './CollectionStatBox';
import { useState } from 'react';
import Button from '@/components/button';
import { useHistory } from 'react-router-dom';
import Brands from './Brands';

const BORDER_RADIUS = 12;

const getStats = (template: Template) => [
  {
    name: 'Total submissions',
    value: template.numRemixes,
  },
  {
    name: 'Total votes',
    value: template.numVotes,
  },
];

interface CollectionCardProps {
  template: Template;
}

const CollectionCard = ({ template }: CollectionCardProps) => {
  const [isHover, setIsHover] = useState(false);

  const history = useHistory();

  const { brands, description, name, previewImageUrl } = template;

  const handleViewProducts = () => history.push(`/collection/${template.id}`);

  return (
    <Box
      border={isHover ? '1px solid' : 'none'}
      borderColor="borderSecondary"
      borderRadius={BORDER_RADIUS}
      color="#000000"
      cursor="pointer"
      flex={1}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleViewProducts}
    >
      <Box position="relative">
        <Image
          borderTopLeftRadius={BORDER_RADIUS}
          borderTopRightRadius={BORDER_RADIUS}
          objectFit="contain"
          src={previewImageUrl}
          alt="Collection image"
          h={{ base: 196, md: 297 }}
          w={{ base: '100%', md: '576px' }}
        />
        {isHover ? (
          <Box bg="rgba(6, 74, 196, 0.20)" position="absolute" left={0} top={0} h="100%" w="100%" />
        ) : null}
      </Box>
      <VStack align="flex-start" padding={{ base: '9px 3px 0 3px', md: '10px 20px 0 20px' }}>
        <Brands brands={brands} height={{ base: '38px', md: '48px' }} isDark />
        <Heading fontSize="24px" mb={{ base: '6px', md: '8px' }} mt="14px">
          {name}
        </Heading>
        <Text fontSize={{ base: '15px', md: '16px' }} textAlign="left">
          {description}
        </Text>
        {isHover ? (
          <Button
            justifySelf="flex-end"
            mt={{ base: '14px', md: '50px' }}
            mb="10px"
            onClick={handleViewProducts}
          >
            View Products
          </Button>
        ) : (
          <HStack mt="12px" spacing="12px">
            {getStats(template).map(({ name, value }, index) => (
              <CollectionStatBox title={name} value={value} key={index} />
            ))}
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export default CollectionCard;
