import { Button, ButtonProps } from '@chakra-ui/react';
import IconVote from './icons/IconVote';
import { useState } from 'react';
import IconVoteHover from './icons/IconVoteHover';

const VoteButtonStyles = {
  bg: 'gray.200',
  borderRadius: '8px',
  color: 'gray.900',
  _hover: {
    bg: '#064AC4',
    border: '1px solid',
    borderColor: 'gray.300',
    color: '#FFFFFF',
  },
  _disabled: {
    bg: '#FFFFFF',
    border: '1px solid',
    borderColor: 'gray.300',
    _hover: {
      color: 'gray.900',
      cursor: 'not-allowed',
      opacity: 1,
    },
  },
};

const VoteButton = (props: ButtonProps) => {
  const [isHover, setIsHover] = useState(false);

  const { isDisabled, ...rest } = props;

  return (
    <Button
      isDisabled={isDisabled}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...VoteButtonStyles}
      {...rest}
    >
      {isDisabled ? null : isHover ? <IconVoteHover mr="8px" /> : <IconVote mr="8px" />}
      Vote
    </Button>
  );
};

export default VoteButton;
