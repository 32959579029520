import { Box, Card, CardBody, Center, HStack, Image, Text, VStack } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import { useEffect, useState } from 'react';
import { getDesignPreviewImage } from '@/lib';
import IconVote from './icons/IconVote';

import CreatorAvatar from '@/views/home/components/CreatorAvatar';
import VoteButton from './VoteButton';
import Button from '../button';
import IconCart from '../icons/IconCart';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../ui/LoadingSpinner';

const BORDER_RADIUS = 12;

const THUMBNAIL_MOBILE_SIZE = 164;
const THUMBNAIL_DESKTOP_SIZE = 185;

const ButtonProps = {
  w: '100%',
};

type RemixCardProps = {
  design: Design;
  votingForDesign: string;
  onVote: () => void;
  onAddToCart: () => void;
};

const RemixCard = ({ design, votingForDesign, onVote, onAddToCart }: RemixCardProps) => {
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  const [isHovered, setIsHovered] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getDesignPreviewImage(design)
      .then(({ previewImage }) => {
        setDesignPreviewImage(previewImage);

        setIsLoadingPreviewImage(false);
      })
      .catch((e) => console.log('E', e));
  }, []);

  const { id, name, numVotes, template, user } = design;

  const handleGoToProductDetails = () => history.push(`/products/${design.id}`);

  const isVotingDisabled = !onVote;

  return (
    <>
      <Card
        bg="transparent"
        borderRadius={BORDER_RADIUS}
        border={isHovered ? '1px solid' : 'none'}
        borderColor="borderSecondary"
        boxShadow="none"
        h={{ base: '257px', lg: '296px' }}
        w="100%"
        key={id}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        position="relative"
      >
        <CardBody p={0}>
          <Center
            bg="secondaryLightGray"
            borderTopLeftRadius={BORDER_RADIUS}
            borderTopRightRadius={BORDER_RADIUS}
            cursor="pointer"
            h={{ base: `${THUMBNAIL_MOBILE_SIZE}px`, lg: `${THUMBNAIL_DESKTOP_SIZE}px` }}
            w={{ base: `${THUMBNAIL_MOBILE_SIZE}px`, lg: `${THUMBNAIL_DESKTOP_SIZE}px` }}
            onClick={() => handleGoToProductDetails()}
            position="relative"
          >
            {isHovered ? (
              <Box
                bg="rgba(6, 74, 196, 0.1)"
                borderTopLeftRadius={BORDER_RADIUS}
                borderTopRightRadius={BORDER_RADIUS}
                position="absolute"
                left={0}
                top={0}
                h="100%"
                w="100%"
              />
            ) : null}
            {isLoadingPreviewImage || !designPreviewImage ? null : (
              <Image h="100%" w="100%" objectFit="contain" src={designPreviewImage} />
            )}
            {isLoadingPreviewImage ? (
              <Center
                position="absolute"
                w="100%"
                h="100%"
                bg={isLoadingPreviewImage ? 'transparent' : 'black'}
                opacity="0.5"
                top={0}
              >
                <LoadingSpinner />
              </Center>
            ) : null}
          </Center>
          <VStack align="left" p={{ base: '10px 0', md: '12px 16px' }} spacing="8px">
            {isHovered ? (
              <VStack>
                <Button small {...ButtonProps} onClick={onAddToCart}>
                  <HStack spacing="6px">
                    <IconCart />
                    <Text as="b" fontSize="sm">
                      Add to cart
                    </Text>
                  </HStack>
                </Button>
                <VoteButton
                  isDisabled={isVotingDisabled}
                  isLoading={votingForDesign === design.id}
                  onClick={onVote}
                  h="38px"
                  p="0 16px"
                  {...ButtonProps}
                >
                  <HStack spacing={1}>
                    {isVotingDisabled ? null : <IconVote />}
                    <Text>
                      {numVotes} Vote{numVotes === 1 ? '' : 's'}
                    </Text>
                  </HStack>
                </VoteButton>
              </VStack>
            ) : (
              <VStack align="flex-start" spacing="6px">
                <Text
                  fontSize="md"
                  fontWeight={700}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {name}
                </Text>
                <CreatorAvatar user={user} />
                <Text as="b" fontSize="sm">
                  ${template.price}
                </Text>
              </VStack>
            )}
          </VStack>
        </CardBody>
      </Card>
    </>
  );
};

export default RemixCard;
