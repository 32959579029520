import { extendTheme } from '@chakra-ui/react';

import { breakpoints } from './breakpoints';

import { globalStyles } from './styles';
import { fonts } from './fonts';
import { components } from './components';

import { textStyles } from './text';

import { borderRadius } from './border-radius';

export default extendTheme(
  { breakpoints, borderRadius, components, fonts, textStyles },
  globalStyles
);
