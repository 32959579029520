import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { Design } from '@/lib';

import { getDesigns } from '@/api/designs';
import RemixCard from '@/components/remix/RemixCard';
import { DesignVote, Template } from '../types';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../ui/LoadingSpinner';

interface RemixesListProps {
  onSelectedDesign: (design: Design) => void;
  template: Template;
  onVote: (designId: string) => void;
  onAddToCart?: (design: Design) => void;
  votingEnded?: boolean;
  maxItems?: number;
  votingForDesign: string;
  designVotes: { [designId: string]: DesignVote[] };
  votedForDesign: string;
}

export default function RemixesList({
  votingEnded,
  onVote,
  onAddToCart,
  maxItems,
  votingForDesign,
  template,
  designVotes,
  votedForDesign,
}: RemixesListProps) {
  const [designs, setDesigns] = useState<Design[]>([]);
  const [hasAllItems, setHasAllItems] = useState(false);

  const [isLoadingNextPage, setLoadingNextPage] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const observer = useRef<IntersectionObserver>(null);

  const itemsPerPage = 8;

  useEffect(() => {
    if (!votedForDesign) {
      return;
    }

    setDesigns(
      designs.map((design) =>
        design.id !== votedForDesign ? design : { ...design, numVotes: design.numVotes + 1 }
      )
    );
  }, [votedForDesign]);

  useEffect(() => {
    if (!template?.id) {
      return;
    }

    setLoadingNextPage(true);

    getDesigns({
      isPublished: true,
      templateId: template.id,
      take: maxItems || itemsPerPage,
      skip: (currentPage - 1) * itemsPerPage,
    }).then((designs) => {
      setDesigns((oldDesigns) => (maxItems ? designs : [...oldDesigns, ...designs]));

      if (maxItems || designs.length < itemsPerPage) {
        setHasAllItems(true);
      }

      setLoadingNextPage(false);
    });
  }, [currentPage, votingEnded, maxItems, template?.id]);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoadingNextPage || hasAllItems) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoadingNextPage, hasAllItems]
  );

  return (
    <>
      <HStack justify="center" spacing="12px" wrap="wrap" w="100%">
        {designs.map((design, index) => (
          <Box
            key={design.id + index}
            ref={index === designs.length - 1 ? lastElementRef : null}
            w={{ base: 'calc(50% - 6px)', '2sm': '184px', lg: '185px' }}
          >
            <RemixCard
              design={design}
              votingForDesign={votingForDesign}
              onAddToCart={() => onAddToCart(design)}
              onVote={!votingEnded && isEmpty(designVotes) ? () => onVote(design.id) : null}
            />
          </Box>
        ))}
      </HStack>
      {isLoadingNextPage ? <LoadingSpinner /> : null}
    </>
  );
}
