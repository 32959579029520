import { Heading, HeadingProps } from '@chakra-ui/react';

export const H1 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '30px', md: '34px' }}
    lineHeight={{ base: '40px', md: '40px' }}
    {...props}
  />
);

export const H2 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '24px', md: '30px' }}
    lineHeight={{ base: '32px', md: '40px' }}
    {...props}
  />
);

export const H3 = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '20px', md: '24px' }}
    lineHeight={{ base: '24px', md: '32px' }}
    {...props}
  />
);

export const H4 = (props: HeadingProps) => <Heading fontSize="20px" lineHeight="24px" {...props} />;

export const H5 = (props: HeadingProps) => <Heading fontSize="17px" lineHeight="22px" {...props} />;
