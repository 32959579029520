import { Button as ChakraButton } from '@chakra-ui/react';
import { Event } from '../../analytics/events';
import { trackEvent } from '../../analytics';

const focusStyles = {
  _focus: {
    color: 'inherit',
  },
  _active: {
    color: 'inherit',
  },
};

const secondaryStyles = {
  border: '1px solid',
  borderColor: '#CBD5E0',
  bg: 'transparent',
  color: '#171923',
  ...focusStyles,
};

const outlinedStyles = {
  ...focusStyles,
};

const smallStyles = {
  borderRadius: '8px',
  fontSize: 'sm',
  height: '38px',
};

const Button = ({
  outlined = false,
  small = false,
  onClick = null,
  secondary = false,
  children,
  ...rest
}) => {
  const handleClick = (e) => {
    // Track the button click
    trackEvent(`${Event.BUTTON_CLICKED}: ${children}`, { text: children });

    // If there is an onClick handler passed, call it
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ChakraButton
      bg={outlined ? '#FFFFFF' : 'brand.600'}
      border={outlined ? '1px solid' : 'none'}
      borderColor="brand.600"
      borderRadius="12px"
      color={outlined ? 'brand.600' : '#FFFFFF'}
      h="50px"
      p="8px 16px"
      onClick={handleClick}
      children={children}
      {...(secondary ? secondaryStyles : {})}
      {...(outlined ? outlinedStyles : {})}
      {...(small ? smallStyles : {})}
      {...rest}
    />
  );
};

export default Button;
