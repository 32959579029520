import {
  Box,
  Card,
  CardBody,
  Center,
  HStack,
  Image,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { useUpdateUser } from '@/api/users';
import { User } from '@/components/types';

import ImageUpload from '@/lib/components/upload/ImageUpload';
import IconUploadImage from '@/components/icons/IconUploadImage';
import getResizedImage from '@/utils/resizeImage';

import { formatRole } from '../../utils/formatters';
import { useState } from 'react';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

const AVATAR_WIDTH = 108;

const AvatarProps = {
  h: `${AVATAR_WIDTH}px`,
  w: `${AVATAR_WIDTH}px`,
  borderRadius: '50%',
};

type HeaderProps = {
  user: User;
};

export default function Header({ user }: HeaderProps) {
  const { avatarUrl, firstName, lastName, roles } = user;

  const [isUploadingImage, setUploadingImage] = useState(false);

  const { updateUser } = useUpdateUser();

  const toast = useToast();

  const handleImageUploaded = (image) => {
    setUploadingImage(true);

    updateUser(
      { id: user.id, avatarBase64: getResizedImage(image.src, image.width, image.height) },
      {
        onError: () => {
          toast({
            position: 'top',
            title: 'Error updating account',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });

          setUploadingImage(false);
        },
        onSuccess: () => {
          setUploadingImage(false);
        },
      }
    );
  };

  return (
    <Card bg="#FFFFFF" borderRadius="20px" w="100%">
      <CardBody p="50px 66px 0 50px">
        <Box
          bg="linear-gradient(93deg, #4318FF 24.85%, #FFB800 97.05%)"
          borderRadius="13px"
          h="196px"
          w="100%"
        />
        <VStack position="relative" bottom="54px">
          <ImageUpload
            customButtonContent={
              <Box position="relative">
                {avatarUrl ? (
                  <Image src={avatarUrl} {...AvatarProps} mb="6px" />
                ) : (
                  <Center bg="gray.50" {...AvatarProps}>
                    <IconUploadImage />
                  </Center>
                )}
                {isUploadingImage ? (
                  <LoadingSpinner
                    color="#FFFFFF"
                    left="calc(50% - 12px)"
                    position="absolute"
                    top="calc(50% - 12px)"
                  />
                ) : null}
              </Box>
            }
            onImagesUploaded={(images) => handleImageUploaded(images[0])}
          />
          <Text color="secondaryDarkGrey.900" fontSize="19px" fontWeight={700}>
            {firstName} {lastName}
          </Text>
          <HStack spacing="7px">
            <Text color="secondaryGray.600" fontSize="md">
              Account type:
            </Text>
            <Text color="secondaryDarkGrey.900" fontSize="15px" fontWeight={700}>
              {formatRole(roles[0].name)}
            </Text>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
}
