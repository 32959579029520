import { getOrders } from '@/api/cart';
import { getDesign } from '@/api/designs';
import { H2 } from '@/components/typography/Headings';
import AppContainer from '@/layouts/AppContainer';
import { Center, Flex, Spinner, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import OrderSummaryCard from './components/OrderSummaryCard';
import { isEmpty } from 'lodash';
import NoOrdersEmptyState from './components/NoOrdersEmptyState';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const prepareOrders = useCallback(async () => {
    const resOrders = await getOrders();

    const promises = [];

    for (const order of resOrders) {
      const designsPromise = Promise.all(
        order.items.map((item) =>
          getDesign(item.designId).catch(() => {
            return null;
          })
        )
      ).then((designs) => {
        order.designs = designs;

        order.totalPrice = order.items.reduce((result, cartItem, index) => {
          const { quantity } = cartItem;

          const design = designs[index];

          return result + (design?.template?.price || 0) * quantity;
        }, 0);
      });
      promises.push(designsPromise);
    }

    await Promise.all(promises);

    resOrders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    setOrders(resOrders);
    setLoading(false);
  }, []);

  useEffect(() => {
    prepareOrders();
  }, [prepareOrders]);

  return (
    <AppContainer contentContainerProps={{ padding: 0 }}>
      {isLoading ? (
        <Center bg="#FFFFFF" h="100%">
          <Spinner thickness="1px" speed="0.65s" emptyColor="gray" size="md" />
        </Center>
      ) : (
        <Flex
          direction="column"
          bg="#FFFFFF"
          h="100%"
          w="100%"
          p={{ base: '20px 16px', md: '24px 33px' }}
        >
          <H2 mb={{ base: '17px', md: '21px' }}>My orders</H2>
          {isEmpty(orders) ? (
            <NoOrdersEmptyState />
          ) : (
            <VStack align="flex-start" spacing="33px" w="100%" maxW="972px">
              {orders.map((item, index) => (
                <OrderSummaryCard order={item} key={index} />
              ))}
            </VStack>
          )}
        </Flex>
      )}
    </AppContainer>
  );
};

export default Orders;
