import { getCategories, getTemplates } from '@/api/templates';
import { Category, Template } from '@/components/types';

import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import CollectionCard from './CollectionCard';
import { useLocation } from 'react-router-dom';
import { findIndex } from 'lodash';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

const CollectionsList = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  const [loading, setLoading] = useState(true);

  const [templatesForCategories, setTemplatesForCategories] = useState<Record<string, Template[]>>(
    {}
  );

  const [loadingTemplates, setLoadingTemplates] = useState<Record<string, boolean>>({});

  const [defaultCategoryIndex, setDefaultCategoryIndex] = useState<number>();

  const toast = useToast();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const defaultCategoryId = searchParams.get('categoryId');

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const orderedCategories = await getCategories();

        setCategories(orderedCategories);

        const defaultCategoryIndex = Math.max(
          findIndex(orderedCategories, ({ id }) => defaultCategoryId === id),
          0
        );

        setDefaultCategoryIndex(defaultCategoryIndex);

        loadTemplates(orderedCategories[defaultCategoryIndex].id);
      } finally {
        setLoading(false);
      }
    };

    loadCategories();
  }, []);

  const loadTemplates = async (categoryId) => {
    if (templatesForCategories[categoryId]) {
      return;
    }

    setLoadingTemplates({
      ...loadingTemplates,
      [categoryId]: true,
    });

    try {
      const templates = await getTemplates({ categoryId });

      setTemplatesForCategories({
        ...templatesForCategories,
        [categoryId]: templates,
      });
    } catch {
      toast({
        position: 'top',
        title: 'Error loading templates',
        status: 'error',
        duration: 1000,
      });
    } finally {
      setLoadingTemplates({
        ...loadingTemplates,
        [categoryId]: false,
      });
    }
  };

  return (
    <Box h="100%" bg="#FFFFFF" padding={{ base: '16px', md: '32px 30px' }} overflow="auto">
      <Heading fontSize={{ base: '30px', md: '34px' }} mb="26px">
        Explore featured collections!
      </Heading>
      {loading ? (
        <Center bg="transparent" h="100%">
          <LoadingSpinner />
        </Center>
      ) : (
        <Tabs defaultIndex={defaultCategoryIndex}>
          <Box overflow="auto">
            <TabList borderBottom="none" mb="12px" w="max-content">
              {categories.map((category, index) => (
                <Tab
                  color="secondaryDarkGray.600"
                  fontSize={{ base: '16px', md: '18px' }}
                  key={category.id}
                  ml={index === 0 ? 0 : { base: '38px', md: '40px' }}
                  padding="0"
                  _selected={{
                    borderBottomWidth: '2px',
                    borderBottomColor: 'brand.600',
                    color: 'brand.600',
                    fontWeight: 600,
                  }}
                  onClick={() => loadTemplates(category.id)}
                >
                  {category.name}
                </Tab>
              ))}
            </TabList>
          </Box>
          <TabPanels>
            {categories.map((category) => {
              const templatesForCategory = templatesForCategories[category.id] || [];

              return (
                <TabPanel padding="0" key={category.id} position="relative">
                  {loadingTemplates[category.id] ? (
                    <Center
                      position="absolute"
                      w="100%"
                      h="calc(100vh - 300px)"
                      opacity="0.5"
                      top={0}
                    >
                      <LoadingSpinner />
                    </Center>
                  ) : (
                    <SimpleGrid
                      columns={{ base: 1, md: 2 }}
                      gap={{ base: '32px', md: '24px' }}
                      maxH={{ base: 'none', md: 'none' }}
                      overflow="auto"
                      padding={{ base: '8px 0', md: '32px 0' }}
                    >
                      {templatesForCategory.map((template) => {
                        return <CollectionCard template={template} key={template.id} />;
                      })}
                    </SimpleGrid>
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};

export default CollectionsList;
