import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Hide,
  Image,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';

import RemixesList from '@/components/remix/RemixesList';

import IconBack from '@/lib/components/icons/IconBack';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCategories, useTemplate } from '../../api/templates';
import { Design } from '../../lib';
import { CSSProperties, useEffect, useState } from 'react';
import RemixDetails from '../home/RemixDetails';
import { voteForDesign } from '../../api/designs';
import { isEmpty } from 'lodash';
import RemixDetailsModal from '../home/RemixDetailsModal';
import { Template } from '../../components/types';
import IconChevronDown from '@/components/icons/IconChevronDown';
import AddToCartModal from '@/components/cart/AddToCartModal';
import Brands from './components/Brands';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

const IMAGE_DESKTOP_SIZE = 420;

const getStats = (template: Template) => [
  {
    name: 'Total submissions',
    value: template.numRemixes,
  },
  {
    name: 'Total votes',
    value: template.numVotes,
  },
];

interface CollectionPageProps {
  onBack?: () => void;
  votingForDesign?: string;
  votingEnded?: boolean;
  votedForDesign?: string;
  onSignInToVote?: (designId: string) => void;
  onSignInToAddToCart?: (designToAdd: string) => void;
}

export default function CollectionPage({
  onBack,
  onSignInToVote,
  onSignInToAddToCart,
  votingEnded,
}: CollectionPageProps) {
  const { id } = useParams<{ id: string }>();
  const { data: template, isLoading, isError } = useTemplate(id);

  const { data: categories = [] } = useCategories();

  const [design, setDesign] = useState<Design | null>(null);
  const [showRemixDetails, setShowRemixDetails] = useState(false);

  const [designToAddToCart, setDesignToAddToCart] = useState<string>(null);

  const [votingForDesign, setVotingForDesign] = useState<string>(null);
  const [designVotes, setDesignVotes] = useState({});
  const [votedForDesign, setVotedForDesign] = useState<string>(null);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const history = useHistory();
  const toast = useToast();

  const { search } = useLocation();

  const {
    brands,
    categoryId,
    collectionDescription = '',
    name,
    numRemixes,
    previewImageUrl,
  } = template || {};

  const category = categories.find(({ id }) => id === categoryId);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    const addingDesignToCart = queryParams.get('designToAddToCart');

    if (addingDesignToCart) {
      handleAddToCart(addingDesignToCart);
    }
  }, []);

  const handleDesignSelected = (design: Design) => {
    setDesign(design);
    setShowRemixDetails(true);
  };

  const handleAddToCart = (designId: string) => {
    if (onSignInToAddToCart) {
      onSignInToAddToCart(designId);

      return;
    }

    setDesignToAddToCart(designId);
  };

  const handleVote = (designToVoteForId: string) => {
    if (onSignInToVote) {
      onSignInToVote(designToVoteForId);

      return;
    }

    setVotingForDesign(designToVoteForId);

    voteForDesign(designToVoteForId)
      .then((newDesignVote) => {
        const votesForDesign = designVotes[designToVoteForId] || [];

        setDesignVotes({
          ...designVotes,
          [designToVoteForId]: [...votesForDesign, newDesignVote],
        });

        setVotedForDesign(designToVoteForId);

        setTimeout(() => {
          setVotedForDesign(null);
        }, 1000);
      })
      .catch((e) => {
        const hasVoted = e.response?.status === 409;

        toast({
          position: 'top',
          title: hasVoted ? 'You already voted for this design' : 'Error voting for design',
          status: 'error',
          duration: 1000,
        });
      })
      .finally(() => {
        setVotingForDesign(null);
      });
  };

  const handleGoBackToCategory = () => history.push(`/collections?categoryId=${categoryId}`);

  if (showRemixDetails && isMobile) {
    return (
      <RemixDetails
        designId={design.id}
        designVotes={designVotes}
        isVoting={false}
        onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
      />
    );
  }

  if (isError) {
    toast({
      title: 'Error loading collection',
      status: 'error',
      duration: 2000,
    });
  }

  return (
    <Box bg="#FFFFFF" h="100%" overflow="auto" pb="80px">
      {isLoading ? (
        <Center position="absolute" w="100%" h="100%" bg={'transparent'} opacity="0.5" top={0}>
          <LoadingSpinner />
        </Center>
      ) : (
        template && (
          <>
            {onBack ? (
              <Hide above="md">
                <Box p="12px 20px 12px 0">
                  <Button onClick={onBack} variant="ghost">
                    <IconBack />
                  </Button>
                </Box>
              </Hide>
            ) : null}
            <Box position="relative">
              <Image
                objectFit="cover"
                src={previewImageUrl}
                alt="Collection image"
                h={{ base: 'auto', md: IMAGE_DESKTOP_SIZE }}
                w="100%"
              />
              <Box
                bg={{
                  base: 'linear-gradient(85.87deg, rgba(0, 0, 0, 0.7) -12.58%, rgba(0, 0, 0, 0) 90.21%)',
                  md: 'linear-gradient(66.24deg, rgba(0, 0, 0, 0.7) -6.65%, rgba(0, 0, 0, 0) 45.56%);',
                }}
                padding={{ base: '21px 16px', md: 0 }}
                position="absolute"
                h="100%"
                w="100%"
                left={0}
                top={0}
              >
                <HStack
                  gap="4px"
                  position={{ base: 'static', md: 'absolute' }}
                  left="32px"
                  top="24px"
                >
                  <Text
                    as="b"
                    color="#FFFFFF"
                    cursor="pointer"
                    fontSize={{ base: '12px', md: '13px' }}
                    onClick={handleGoBackToCategory}
                  >
                    {category?.name}
                  </Text>
                  <IconChevronDown
                    color="#FFFFFF"
                    height="16px"
                    width="16px"
                    transform="rotate(-90deg)"
                  />
                  <Text color="#FFFFFF" fontSize={{ base: '12px', md: '13px' }} opacity={0.6}>
                    {name}
                  </Text>
                </HStack>
                <Box
                  mt={{ base: '10px', md: 0 }}
                  p={{ base: '0', md: '24px 32px 32px 32px' }}
                  position={{ base: 'static', md: 'absolute' }}
                  left={0}
                  bottom={0}
                >
                  <Brands
                    brands={brands}
                    height={{ base: '47px', md: '51px' }}
                    mb={{ base: '9px', md: '22px' }}
                    mt={{ base: '30px', md: 0 }}
                  />
                  <Heading color="#FFFFFF" fontSize={{ base: '30px', md: '34px' }}>
                    {name}
                  </Heading>
                  <Text
                    color="#FFFFFF"
                    fontSize={{ base: '15px', md: '16px' }}
                    mt={{ base: '3px', md: '7px' }}
                    w={{ base: 'auto', md: '381px' }}
                    display="-webkit-box"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="normal"
                    style={
                      {
                        '-webkit-box-orient': 'vertical',
                        '-webkit-line-clamp': '3',
                      } as CSSProperties
                    }
                  >
                    {collectionDescription}
                  </Text>
                  <HStack mt={{ base: '12px', md: '29px' }} spacing="14px">
                    {getStats(template).map(({ name, value }) => (
                      <Box
                        border="1px solid #FFF4F4"
                        borderRadius={12}
                        key={name}
                        padding={{ base: '9px 12px', md: '14px 17px' }}
                        w={{ base: '147px', md: '172px' }}
                      >
                        <Text
                          color="#FFFFFF"
                          fontSize={{ base: '13px', md: '16px' }}
                          fontWeight={500}
                        >
                          {name}
                        </Text>
                        <Text color="#FFFFFF" fontSize="24px" fontWeight={700}>
                          {value ? value.toLocaleString() : 0}
                        </Text>
                      </Box>
                    ))}
                  </HStack>
                </Box>
              </Box>
            </Box>
            <VStack
              align="flex-start"
              gap={0}
              p={{ base: '20px 16px', md: '60px 32px 100px 32px' }}
            >
              <Heading
                mb="4px"
                fontSize={{ base: '20px', md: '30px' }}
                fontWeight={700}
                w={{ base: '260px', md: 'auto' }}
              >
                {votingEnded ? 'Meet The Winners!' : 'Limited merch - make it yours today!'}
              </Heading>
              <Text fontSize={{ base: '14px', md: '16px' }} mb={{ base: '17px', md: '22px' }}>
                Items: {numRemixes}
              </Text>
              <RemixesList
                votingForDesign={votingForDesign}
                votingEnded={votingEnded}
                onVote={handleVote}
                onAddToCart={(design) => handleAddToCart(design.id)}
                onSelectedDesign={handleDesignSelected}
                template={template}
                designVotes={designVotes}
                votedForDesign={votedForDesign}
              />
            </VStack>
            {showRemixDetails && (
              <RemixDetailsModal
                designId={design.id}
                designVotes={designVotes}
                isVoting={votingForDesign === design.id}
                onClose={() => setShowRemixDetails(false)}
                onVote={!votingEnded && isEmpty(designVotes) ? handleVote : null}
                votedForDesign={votedForDesign}
              />
            )}
            {designToAddToCart && (
              <AddToCartModal
                designId={designToAddToCart}
                goBackText="Back to list"
                onClose={() => setDesignToAddToCart(null)}
              />
            )}
          </>
        )
      )}
    </Box>
  );
}
