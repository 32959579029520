import { useEffect, useState } from 'react';

import { HStack, VStack, Text, useToast, Spinner } from '@chakra-ui/react';

import { capitalize } from 'lodash';

import { useCurrencies } from '@/api/currencies';

import FormInputBase from '@/components/form/FormInput';
import { updateTemplate } from '@/api/templates';

import Dropdown from '@/components/form/Dropdown';
import FormContainer from '../components/FormContainer';
import { Template } from '@/components/types';
import ImageUploadButton from '../components/ImageUploadButton';
import FormLabel from '@/components/form/FormLabel';
import { ARTBOARD_CATEGORY_OPTIONS } from '@/constants/artboards';
import Multiselect from '@/components/multiselect';
import { getStyles } from '@/api/style';
import { uploadBlobToStorage } from '../../../../../api/storage';
import { IStyle } from '@space-runners/ablo-ts-sdk/lib/services/style/style.interface';

const FIELD_INROW_SPACING = '38px';
const INPUT_WIDTH = '307px';

const FIT_OPTIONS = ['Regular', 'Tight', 'Loose', 'Oversized'];

const GENDER_OPTIONS = ['male', 'female', 'unisex'];

const DEFAULT_VALUES = {
  brand: '',
  currencyId: 1,
  description: '',
  fit: 'Regular',
  gender: 'unisex',
  madeIn: '',
  fabric: '',
  pinnedStyleIds: [],
};

const FormInput = (props) => <FormInputBase w={INPUT_WIDTH} {...props} />;

type GeneralInformationProps = {
  onNext: (updates: object) => void;
  onUpdate: (updates: object) => void;
  templateInfo: Partial<Template>;
};

const GeneralInformation = ({ onNext, onUpdate, templateInfo }: GeneralInformationProps) => {
  const [template, setTemplate] = useState<Partial<Template>>(DEFAULT_VALUES);
  const [waiting, setWaiting] = useState(false);
  const [isUploadingPreviewImage, setIsUploadingPreviewImage] = useState(false);
  const [newArtboardIcon, setNewArtboardIcon] = useState<string>();
  const [newPreviewImage, setNewPreviewImage] = useState<string>();
  const [styles, setStyles] = useState<IStyle[]>([]);

  useEffect(() => {
    const loadStyles = async () => {
      const styles = await getStyles();

      setStyles(styles);
    };

    const loadPinnedStyles = async () => {
      const styles = await getStyles(templateInfo.id, true);

      setTemplate({
        ...DEFAULT_VALUES,
        ...templateInfo,
        pinnedStyleIds: styles.map(({ id }) => id),
      });
    };

    loadStyles();

    if (templateInfo.id) {
      loadPinnedStyles();
    } else {
      setTemplate({
        ...DEFAULT_VALUES,
        ...templateInfo,
      });
    }
  }, [templateInfo]);

  const { data: currencies = [] } = useCurrencies();

  const toast = useToast();

  const {
    brand,
    currencyId,
    fabric,
    gender,
    madeIn,
    name,
    fit,
    price,
    collectionDescription,
    description,
    manufacturingProductId,
    artboardCategory,
    artboardSizeDescription,
    artboardIconUrl,
    pinnedStyleIds,
    previewImageUrl,
  } = template;

  const hasAllFieldsFilled = name;

  const { id } = templateInfo;

  const handleUpdate = async (updates) => {
    if (updates.previewImage) {
      setIsUploadingPreviewImage(true);
      try {
        const contentType = updates.previewImage.split(';')[0].split(':')[1];

        updates.previewImageUrl = await uploadBlobToStorage(updates.previewImage, contentType);
        toast({
          title: 'Image uploaded',
          position: 'top',
          status: 'success',
        });
      } catch (error) {
        console.error('Error uploading image:', error);

        toast({
          title: 'Error uploading image',
          position: 'top',
          status: 'error',
        });
      } finally {
        setIsUploadingPreviewImage(false);
      }
    }

    setTemplate({ ...template, ...updates });
  };

  const handleNext = () => {
    if (!id) {
      onNext(template);

      return;
    }

    setWaiting(true);

    delete template.previewImage;

    updateTemplate(template)
      .then((data) => {
        toast({
          title: 'Changes saved',
          position: 'top',
          status: 'success',
        });
        onUpdate(data);
      })
      .finally(() => setWaiting(false));
  };

  return (
    <FormContainer
      onNext={handleNext}
      nextDisabled={!hasAllFieldsFilled}
      nextLabel={id && 'Save'}
      title="Template Information"
      waiting={waiting}
    >
      <VStack align="flex-start" spacing="20px" w="100%">
        <HStack spacing={FIELD_INROW_SPACING} w="100%">
          <VStack align="flex-start" spacing="20px" w="100%">
            <HStack spacing={FIELD_INROW_SPACING}>
              <FormInput
                name="Template Name"
                placeholder="Ex) Regular T-Shirt"
                value={name}
                onChange={(e) => handleUpdate({ name: e.target.value })}
              />
              <FormInput
                name="Fabric"
                isOptional
                placeholder="Ex) Recycled cotton jersey"
                value={fabric}
                onChange={(e) => handleUpdate({ fabric: e.target.value })}
              />
            </HStack>
            <HStack spacing={FIELD_INROW_SPACING}>
              <HStack align="flex-end">
                <Dropdown
                  isOptional
                  name="Price"
                  options={currencies}
                  selectedValue={currencyId}
                  onSelectedValue={(currencyId) => handleUpdate({ currencyId })}
                  width="auto"
                />
                <FormInputBase
                  placeholder="Ex) 34"
                  value={price}
                  onChange={(e) => handleUpdate({ price: parseFloat(e.target.value) })}
                  type="number"
                  w="230px"
                />
              </HStack>
              <FormInput
                isOptional
                name="Made"
                placeholder="Ex) Made In Portugal"
                value={madeIn}
                onChange={(e) => handleUpdate({ madeIn: e.target.value })}
              />
            </HStack>
          </VStack>
        </HStack>
        <HStack spacing={FIELD_INROW_SPACING}>
          <Dropdown
            name="Fit"
            isOptional
            options={FIT_OPTIONS.map((option) => ({ name: option, id: option }))}
            selectedValue={fit}
            onSelectedValue={(fit) => handleUpdate({ fit })}
            width={INPUT_WIDTH}
          />
          <Dropdown
            isOptional
            name="Gender"
            options={GENDER_OPTIONS.map((option) => ({ name: capitalize(option), id: option }))}
            selectedValue={gender}
            onSelectedValue={(gender) => handleUpdate({ gender })}
            width={INPUT_WIDTH}
          />
          <FormInput
            isOptional
            name="Brand"
            placeholder=""
            value={brand}
            onChange={(e) => handleUpdate({ brand: e.target.value })}
          />
        </HStack>
        <FormInput
          isOptional
          isTextArea
          name="Product Description"
          placeholder="Ex) Fabric made from 50% reclaimed cotton and 50% organic cotton. Jersey for that oh so soft feel. Treated with PRRMINT Oil. A durable odor control and broad-spectrum antimicrobial treatment that enables our products to stay fresh for longer."
          value={description}
          onChange={(e) => handleUpdate({ description: e.target.value })}
          size="lg"
          w="100%"
        />
        <FormInput
          isOptional
          isTextArea
          name="Collection Description"
          placeholder="Ex) A description for the associated collection"
          value={collectionDescription}
          onChange={(e) => handleUpdate({ collectionDescription: e.target.value })}
          size="lg"
          w="100%"
        />
        <FormInputBase
          isOptional
          name="Manufacturing Id"
          placeholder="Printful Product ID"
          value={manufacturingProductId}
          onChange={(e) => handleUpdate({ manufacturingProductId: e.target.value })}
          w="200px"
        />
        <VStack align="flex-start" w="100%">
          <FormLabel isOptional>Preview Image</FormLabel>
          {isUploadingPreviewImage ? (
            <Spinner />
          ) : (
            <>
              <FormInput
                isOptional
                name="Preview Image URL"
                value={previewImageUrl}
                onChange={(e) => handleUpdate({ collectionDescription: e.target.value })}
                size="lg"
                w="100%"
              />
              <ImageUploadButton
                image={newPreviewImage || previewImageUrl}
                onImageUploaded={async (image) => {
                  await handleUpdate({ previewImage: image });
                  setNewPreviewImage(image);
                }}
                title="Artboard Icon"
              />
            </>
          )}
        </VStack>

        <Text color="black.600" fontSize="18px" mt="20px">
          Artboards
        </Text>
        <HStack>
          <Dropdown
            isOptional
            name="Artboard Category"
            options={ARTBOARD_CATEGORY_OPTIONS.map((option) => ({
              name: capitalize(option),
              id: option,
            }))}
            selectedValue={artboardCategory || ''}
            onSelectedValue={(artboardCategory) => handleUpdate({ artboardCategory })}
            width={INPUT_WIDTH}
          />
          <FormInput
            name="Artboard Size Description"
            isOptional
            min={0}
            value={artboardSizeDescription || ''}
            onChange={(e) => handleUpdate({ artboardSizeDescription: e.target.value })}
          />
        </HStack>
        <Multiselect
          name="Pinned styles"
          options={styles.map((style) => ({ ...style, name: `${style.name} (${style.type})` }))}
          onUpdateSelectedValues={(values) => handleUpdate({ pinnedStyleIds: values })}
          selectedValues={pinnedStyleIds}
        />
        <FormInput
          name="Artboard Size Description"
          isOptional
          min={0}
          value={artboardSizeDescription || ''}
          onChange={(e) => handleUpdate({ artboardSizeDescription: e.target.value })}
        />
        <VStack align="flex-start">
          <FormLabel isOptional>Icon</FormLabel>
          <FormLabel isOptional>(Click to update)</FormLabel>
          <ImageUploadButton
            image={newArtboardIcon || artboardIconUrl}
            onImageUploaded={(image) => {
              handleUpdate({ artboardIconBase64: image });
              setNewArtboardIcon(image);
            }}
            title="Artboard Icon"
          />
        </VStack>
      </VStack>
    </FormContainer>
  );
};

export default GeneralInformation;
