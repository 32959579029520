import { Icon } from '@chakra-ui/react';

const IconOrders = () => (
  <Icon
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 1.55664L28.3636 6.75361V23.2461L15 28.4431L1.63635 23.2461V6.75361L15 1.55664ZM3.81817 9.0946L13.9091 13.0188V25.6779L3.81817 21.7536V9.0946ZM16.0909 25.6779L26.1818 21.7536V9.0946L16.0909 13.0188V25.6779ZM15 11.1021L24.2629 7.49987L21.1364 6.284L11.8735 9.88623L15 11.1021ZM8.86363 8.71573L18.1265 5.1135L15 3.89764L5.73711 7.49987L8.86363 8.71573Z"
      fill="#212529"
    />
  </Icon>
);

export default IconOrders;
