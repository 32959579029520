import DesignSideImage from '@/views/demo/pages/design/components/DesignSideImage';
import { Box, Flex, Hide, HStack, Show, Text, useBreakpointValue } from '@chakra-ui/react';
import { getFormattedAmount } from '../../utils/currency-formatter';

const THUMBNAIL_SIZE = 62;
const THUMBNAIL_MOBILE_SIZE = 80;

const OrderSummaryCard = ({ order }) => {
  const { items, designs } = order;

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box border="1px solid" borderColor="borderSecondary" borderRadius="md" w="100%">
      <Box
        bg="#EAEAEA8F"
        borderBottom="1px solid #CBD6E3"
        borderTopLeftRadius="md"
        borderTopRightRadius="md"
        p="14px 24px"
        w="100%"
      >
        <Text as="b" textStyle="subtitle">
          #{order.shopifyOrderId}
        </Text>
      </Box>
      <Box p={{ base: '0 16px', md: '0 20px' }}>
        {items.map((item, index) => {
          const design = designs[index];

          const { template } = design;

          const { price, templateColorSize } = item;

          const {
            size: { name: sizeName },
          } = templateColorSize;

          return (
            <Flex
              alignItems="center"
              borderTop={index === 0 ? 'none' : '1px solid'}
              borderTopColor="borderSecondary"
              justify="space-between"
              p={{ base: '10px 0', md: '12px 0' }}
            >
              <HStack flex={1} spacing="16px">
                <DesignSideImage
                  sideName={template.sides[0].name}
                  height={`${isMobile ? THUMBNAIL_MOBILE_SIZE : THUMBNAIL_SIZE}px`}
                  width={`${isMobile ? THUMBNAIL_MOBILE_SIZE : THUMBNAIL_SIZE}px`}
                  imageWidth={isMobile ? 59 : 46}
                  design={design}
                />
                <Box>
                  <Text fontWeight={{ base: 600, md: 400 }} textStyle="caption">
                    {template.name}
                  </Text>
                  <Hide above="md">
                    <>
                      <Text mt="13px" textStyle="overline" color="secondaryDarkGray.600">
                        Size: {sizeName}
                      </Text>
                      <Text as="b" textStyle="overline">
                        {getFormattedAmount(template.currency?.name, price)}
                      </Text>
                    </>
                  </Hide>
                </Box>
              </HStack>
              <Show above="md">
                <Box flex={1} maxW="150px">
                  <Text textStyle="caption">Size: {sizeName}</Text>
                </Box>
                <Box flex={1} maxW="250px">
                  <Text textStyle="caption">
                    {getFormattedAmount(template.currency?.name, price)}
                  </Text>
                </Box>
              </Show>
            </Flex>
          );
        })}
      </Box>
    </Box>
  );
};

export default OrderSummaryCard;
