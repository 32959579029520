import { Modal, ModalOverlay, ModalContent, ModalBody, Button } from '@chakra-ui/react';
import RemixDetails from './RemixDetails';
import IconCloseModal from '@/components/icons/IconCloseModal';
import { DesignVote } from '@/components/types';

type Props = {
  onClose?: () => void;
  designId: string;
  onVote: (designId: string) => void;
  isVoting: boolean;
  designVotes: { [designId: string]: DesignVote[] };
  votedForDesign: string;
};

export default function RemixDetailsModal({
  designId,
  designVotes,
  isVoting,
  onVote,
  onClose,
  votedForDesign,
}: Props) {
  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent alignSelf="center" borderRadius="24px" h="552px" maxW="none" p={0} w="921px">
        <ModalBody padding={0}>
          <Button
            bg="transparent"
            minWidth="none"
            onClick={onClose}
            position="absolute"
            right="23px"
            top="23px"
          >
            <IconCloseModal />
          </Button>
          <RemixDetails
            designVotes={designVotes}
            designId={designId}
            isVoting={isVoting}
            onVote={onVote}
            votedForDesign={votedForDesign}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
